import VueRouter from "vue-router";

import HelloWorld from "@/components/HelloWorld.vue";
import RareItemView from "@/components/RareItemView.vue";
import SuitItemView from "@/components/SuitItemView.vue";

export default new VueRouter({
    routes: [
        {
            path: '',
            redirect: '/rareItem',
        },
        {
            name: 'RareItemView',
            path: '/rareItem',
            component: RareItemView,
        },
        {
            name: 'SuitItemView',
            path: '/suitItem',
            component: SuitItemView,
        },
        {
            name: 'ActorView',
            path: '/actor',
            component: HelloWorld,
        },
    ]
})