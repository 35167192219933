<template>
    <el-row :gutter="5">
        <el-col :span="leftWidth" class="left-container">
            <el-menu active-text-color="#ffd04b"
                     background-color="#545c64"
                     default-active="1"
                     mode="horizontal"
                     text-color="#fff"
                     @select="menuItemSelect">

                <el-menu-item
                    v-for="it in suitNames"
                    :key="Math.random()"
                    :index="it.id.toString()">
                    <template>
                        <span slot="title"> {{ it.name }} </span>
                    </template>
                </el-menu-item>
            </el-menu>

            <el-table
                ref="itemTable"
                :data="itemList"
                max-height="700"
                :stripe="true"
                :highlight-current-row="true"
                style="width: 100%"
                @row-click="handleRowSelect">

                <el-table-column
                    :key="Math.random()"
                    label="名称"
                    prop="name"
                    width="180">
                </el-table-column>

                <el-table-column
                    :key="Math.random()"
                    label="等级"
                    prop="degree"
                    width="180">
                </el-table-column>

                <el-table-column
                    v-if="activeType.typeCategory === 0"
                    :key="Math.random()"
                    label="防御"
                    prop="defense"
                    width="180">
                </el-table-column>
                <el-table-column
                    v-if="activeType.typeCategory === 1"
                    :key="Math.random()"
                    label="最小伤害"
                    prop="minDamage"
                    width="180">
                </el-table-column>
                <el-table-column
                    v-if="activeType.typeCategory === 1"
                    :key="Math.random()"
                    label="最大伤害"
                    prop="maxDamage"
                    width="180">
                </el-table-column>

                <el-table-column
                    :key="Math.random()"
                    label="备注"
                    prop="remark">
                </el-table-column>
            </el-table>
        </el-col>
        <el-col :span="24 - leftWidth">
            <ItemInfoView></ItemInfoView>
        </el-col>
    </el-row>
</template>

<script>
import {mapActions} from "vuex";
import ItemInfoView from "@/components/ItemInfoView.vue";

export default {
    name: 'SuitItemView',
    components: {ItemInfoView},
    data() {
        return {
            leftWidth: 18,
        }
    },
    watch: {
        suitNames: {
            immediate: true,
            handler (newValue) {
                if (newValue.length === 0) {
                    return;
                }

                this.changeActiveType(newValue[0]).then(
                    type => {
                        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@', type)
                        return this.fetchItemsBySuitId(type.id)
                    }
                ).then (
                    itemList => {
                        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@', itemList)
                        return this.changeActiveItem(itemList[0])
                    }
                )
            }
        }
    },
    methods: {
        ...mapActions(['fetchSuitNames', 'fetchItemsBySuitId','changeActiveType', 'changeActiveItem']),
        menuItemSelect(typeId) {
            typeId = parseInt(typeId);

            if (this.activeType && this.activeType.id === typeId) {
                return
            }

            let type = this.findSuitById(typeId)

            this.changeActiveType(type).then(
                type => {
                    return this.fetchItemsBySuitId(type.id)
                }
            ).then(
                itemList => {
                    return this.changeActiveItem(itemList[0])
                }
            ).catch(
                err => {
                    console.log(err)
                }
            )
        },
        handleRowSelect(row) {
            this.changeActiveItem(row)
        }
    },
    mounted() {

    },
    beforeUpdate() {
        this.$nextTick(() => {
            this.$refs['itemTable'].doLayout()
        })
    }
}
</script>

<style scoped>

</style>