import axios from "axios";

const env = 'prod';

const dev_url_host = 'http://localhost:8080';
const test_url_host = 'http://192.168.33.110:8080';
const prod_url_host = 'http://47.116.71.103';

let url_host;

if (env === 'dev') {
    url_host = dev_url_host;
}
else if (env === 'test') {
    url_host = test_url_host;
}
else if (env === 'prod') {
    url_host = prod_url_host;
}

function isNull(obj) {
    return obj === null || obj === undefined;
}

const URL = {
    allItemTypesUrl() {
        return url_host + '/type/all?pageNum=1&pageSize=100'
    },
    itemByTypeIdUrl(typeId) {
        return url_host + `/items/itemsByType?typeId=${typeId}&pageNum=1&pageSize=100`;
    },
    allSuitNamesUrl() {
        return url_host + '/suit/all';
    },
    addItemUrl() {
        return url_host + '/items/add'
    }

};

const funcLib = {
    objectIsNull(obj) {
      if (isNull(obj)) {
          return true
      }
      if (typeof obj === 'object') {
          return obj.length === 0
      }
      return false
    },
    fetchItemTypes(pageNum, pageSize) {
        pageNum = isNull(pageNum) ? 1 : pageNum
        pageSize = isNull(pageSize) ? 100 : pageSize

        let url = url_host + `/type/all?pageNum=${pageNum}&pageSize=${pageSize}`;
        console.log(url)
        return axios.get(url)
    },
    fetchSuitNames() {
        let url = url_host + `/suit/all`;
        console.log(url)
        return axios.get(url)
    },
    fetchItemsByTypeId(typeId, pageNum, pageSize) {
        pageNum = isNull(pageNum) ? 1 : pageNum
        pageSize = isNull(pageSize) ? 100 : pageSize

        let url = url_host + `/items/itemsByType?typeId=${typeId}&pageNum=${pageNum}&pageSize=${pageSize}`;
        console.log(url)
        return axios.get(url)
    },
    fetchItemsBySuitId(suitId) {
        let url = url_host + `/items/itemsBySuit?suitId=${suitId}`;
        console.log(url)
        return axios.get(url)
    },
    fetchBaseInfo() {
        let url = url_host + `/items/baseInfo`;
        console.log(url)
        return axios.get(url)
    },
    addNewItem(item) {
        let url = url_host + `/items/add`;

        axios.post(url, item).then(
            response => {
                console.log('@新增物品成功', response.data)
            },
            error => {
                console.log('@新增物品失败', error)
            }
        )
    },
    modifyItem(item) {
        let url = url_host + `/items/modify`;

        axios.post(url, item).then(
            response => {
                console.log('@修改物品成功', response.data)
            },
            error => {
                console.log('@修改物品失败', error)
            }
        )
    }
}
export {URL, funcLib};