<template>
    <div>
        <div id="anchor"/>
        <el-card id="card" :body-style="{ padding: '5px' }" :class="auto_fixed">
            <div slot="header" class="clearfix">
                <span> 物品栏 </span>
                <el-button :disabled="!activeItem" type="text">删除</el-button>
                <el-button :disabled="!activeItem" type="text" @click="showModifyItemDlg = true">修改</el-button>
                <el-button type="text" @click="showNewItemDlg = true">新增</el-button>
            </div>
            <div v-if="activeItem === null || activeItem === undefined"> 暂无数据 </div>
            <div v-else>
                <div class="field">
                    <!--                <span class="key"> 名称 </span>-->
                    <span class="value"> {{ activeItem.name }} </span>
                </div>

                <div class="field">
                    <span class="key"> 需要等级 </span>
                    <span class="value">  {{ activeItem.degree }} </span>
                </div>

                <div v-show="activeItem['typeCategory'] === 0" class="field">
                    <span class="key"> 防御  </span>
                    <span class="value"> {{ activeItem.defense }} </span>
                </div>

                <div v-show="activeItem['typeCategory'] === 1" class="field">
                    <span class="key"> 最小伤害 </span>
                    <span class="value">  {{ activeItem.minDamage }} </span>
                </div>

                <div v-show="activeItem['typeCategory'] === 1" class="field">
                    <span class="key"> 最大伤害 </span>
                    <span class="value">  {{ activeItem.maxDamage }} </span>
                </div>

                <div class="field">
                    <span class="key" style="display: block"> 物品图片 </span>

                    <img v-if="activeItem.image" :src="activeItem.image" class="value" />
                    <el-image v-else class="value"
                        style="width: 100px; height: 100px"
                        :src="require('@/assets/default.png')"
                        fit="fit">
                    </el-image>
                </div>
            </div>
        </el-card>


        <el-dialog
            title="新增"
            top="0"
            :visible.sync="showNewItemDlg"
            width="30%"
            :lock-scroll="false"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            @open="openDlg">

            <NewItem v-if="showNewItemDlg" @close="closeDlg" :curType="activeItem.typeId"> </NewItem>

        </el-dialog>

        <el-dialog
            title="修改"
            top="0"
            :visible.sync="showModifyItemDlg"
            width="30%"
            :lock-scroll="false"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            @open="openDlg">

            <ModifyItem v-if="showModifyItemDlg"  @close="closeDlg" :fromItem="activeItem"> </ModifyItem>

        </el-dialog>
    </div>
</template>

<script>
import NewItem from "@/components/NewItem.vue";
import ModifyItem from "@/components/ModifyItem.vue";

export default {
    name: 'ItemInfoView',
    components: {ModifyItem, NewItem},
    data() {
        return {
            auto_fixed: {
                fixed: false
            },
            showNewItemDlg: false,
            showModifyItemDlg: false,
        }
    },
    methods: {
        closeDlg() {
            this.showNewItemDlg = false;
            this.showModifyItemDlg = false;
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
        openDlg() {
            console.log("@@@@@@@@@@@@@@openDlg");
        }
    },
    computed: {
        // itemType() {
        //     if (this.activeItem) {
        //         console.log(this.activeItem.typeId)
        //         console.log(this.itemTypes)
        //         return this.findTypeById(this.activeItem.typeId)
        //     }
        //
        //     return this.itemTypes[0]
        // }
    }
}
</script>

<style scoped>
.el-button {
    float: right;
    padding: 3px 0;
    margin: 0 5px
}

.field {
    display: block;
    margin: 5px;
    padding: 10px;
}

.value {
    font-weight: bold;
    font-size: 20px;
}

img {
    width: 100%;
    height: 500px;
    justify-content: center;
}

</style>