import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'

import ElementUI from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"

import store from './store'
import router from "@/router";

import {mixin} from "@/mixin";

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(ElementUI)

Vue.mixin(mixin)

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
