<template>
    <div id="app">
        <el-container class="main-container">
            <el-header>
                <h1 class="title">暗黑破化神2</h1>
            </el-header>

            <el-container class="content-container">
                <el-aside class="el-aside">
                    <MyNavigator/>
                </el-aside>
                <el-main>
                    <router-view/>
                </el-main>
            </el-container>

            <el-footer>
                <span class="footer-span">
                    &copy; Jason, email <a href="mailto:jason@gmail.com">jason@gmail.com</a>
                </span>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
import MyNavigator from "@/components/MyNavigator.vue";

export default {
    name: 'App',
    components: {MyNavigator},
    beforeMount() {
        this.$store.dispatch('fetchBaseInfo')
    },
    mounted() {
        console.log('App mounted');
    }
}
</script>

<style scoped>
#app {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
}

.el-header{
    width: 100%;
    background-color: black;
    text-align: center;
    color: white;
    letter-spacing: 0.5em;
    padding: 0;
}

.el-header > h1 {
    line-height: 60px;
    margin: 0;
}

.content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.el-footer {
    width: 100%;
    align-items: center;
    text-align: center;
    background-color: #07ab74;
    padding: 0;
    margin: 0;
}

.el-footer > span {
    line-height: 60px;
    margin: 0;
}
</style>
