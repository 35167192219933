<template>
    <el-menu
        text-color="#fff"
        active-text-color="#ffd04b"
        background-color="#545c64"
        default-active="/rareItem"
        router>

        <el-menu-item
            v-for="menu in menuItems"
            :key="menu.key"
            :index="menu.index">
            <template>
                <a :class="menu.icon"></a>
                <span slot="title"> {{ menu.title }} </span>
            </template>
        </el-menu-item>
    </el-menu>
</template>

<script>
export default {
    name: 'MyNavigator',
    data() {
        return {
            menuItems: [
                {
                    title: '暗金装备',
                    index: '/rareItem',
                    key: 'rareItem',
                    icon: 'el-icon-location'
                },
                {
                    title: '人物套装',
                    index: '/suitItem',
                    key: 'suitItem',
                    icon: 'el-icon-s-custom'
                },
                {
                    title: '符文之语',
                    index: '/actor',
                    key: 'actor',
                    icon: 'el-icon-s-custom'
                },
                {
                    title: '技能数',
                    index: '/skill',
                    key: 'skill',
                    icon: 'el-icon-s-marketing',
                },
            ],
        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>

<style scoped>
.el-menu {
    height: 100%;
    width: 100%;
}
</style>