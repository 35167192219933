import {mapState} from "vuex";

export const mixin = {
    methods: {
        findTypeById(typeId) {
            if (typeof typeId !== "number") {
                typeId = parseInt(typeId);
            }

            return this.itemTypes.find(it => it.id === typeId);
        },
        findSuitById(suitId) {
            if (typeof suitId !== "number") {
                suitId = parseInt(suitId);
            }

            return this.suitNames.find(sn => sn.id === suitId);
        },
    },
    computed: {
        ...mapState(['itemTypes', 'suitNames', 'itemList', 'activeItem', "activeType"]),
    }
}