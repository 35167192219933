<template>
    <div class="container">
        <div class="field">
            <span class="key"> 测试字段 </span>
            <span class="value"> {{ this .item }}</span>
        </div>

        <div class="field">
            <span class="key"> 物品名称 </span>
            <input v-model="item.name" class="value" placeholder="在这里输入物品名称">
        </div>

        <div class="field">
            <span class="key"> 物品等级 </span>
            <input v-model.number="item.degree" class="value" type="number"
                  min="1" max="99" placeholder="使用等级">
        </div>

        <div class="field">
            <input v-model.number="item.category" name="category" type="radio" value="0">无形装备
            <input v-model.number="item.category" name="category" type="radio" value="1">绿色装备
            <input v-model.number="item.category" name="category" type="radio" value="2">人物套装
        </div>

        <div v-show="item.category === 2" class="field">
            <span class="key"> 套装名称 </span>
            <select v-model="item.suitId">
                <option v-for="sn in suitNames" :key="sn.id" :value="sn.id"> {{ sn.name }}</option>
            </select>
        </div>

        <div class="field">
            <span class="key"> 物品类别 </span>
            <select v-model="item.typeId">
                <option v-for="it in itemTypes" :key="it.id" :value="it.id"> {{ it.name }}</option>
            </select>

<!--            <div class="field">-->
                <input v-model="item.obtained" class="checkbox-value" type="checkbox">已获得
<!--            </div>-->
        </div>

        <div v-show="itemType['typeCategory']===0" class="field">
            <span class="key"> 防御力 </span>
            <input v-model.number="item.defense" class="value" placeholder="防御力">
        </div>

        <div v-show="itemType['typeCategory']===1" class="field">
            <span class="key"> 最小伤害 </span>
            <input v-model.number="item.minDamage" class="value" placeholder="最小伤害">
        </div>

        <div v-show="itemType['typeCategory']===1" class="field">
            <span class="key"> 最大伤害 </span>
            <input v-model.number="item.maxDamage" class="value" placeholder="最大伤害">
        </div>

        <div class="field">
            <span class="key"> 备注 </span>
            <input v-model="item.remark" class="value" placeholder="备注" type="text">
        </div>

        <div class="field">
            <span class="key"> 图片地址 </span>
            <input v-model="item.image" class="value" placeholder="图片地址" type="text">
        </div>

        <div class="footer">
            <el-button @click="onCancel(false)">取 消</el-button>
            <el-button type="primary" @click="onCancel(true)">确 定</el-button>
        </div>

    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: 'NewItem',
    data() {
        return {
            show: false,
            item: {
                name: "物品名称",
                degree: 1,
                typeId: this.curType,
                defense: 0,
                minDamage: 0,
                maxDamage: 0,
                remark: '',
                image: '',
                obtained: 1,
                category: 1,
                suitId: 0
            },
        }
    },
    props: ['curType'],
    computed: {
        itemType() {
            return this.findTypeById(this.item.typeId)
        },
    },
    methods: {
        ...mapActions(['addNewItem']),
        handleClose() {
            console.log("closed");
            this.show = false
        },
        onCancel(ifSaveItem) {
            if (ifSaveItem) {
                console.log(this.item)
                this.addNewItem(this.item)
            }

            this.$emit("close")
        }
    }
}
</script>

<style scoped>
.container {
    display: block;
}

.key {
    display:inline-block;
    width: 60px;
    text-align: right;
    margin-right: 5px;
}

.field {
    margin: 20px 0;
}

.checkbox-value {
    margin-left: 20px;
}
.footer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.el-button {
    margin: 15px;
}
</style>