import Vue from 'vue'
import Vuex from 'vuex'

import {funcLib} from "@/js";

Vue.use(Vuex)

const actions = {
    fetchTypeList({commit}) {
        return new Promise(
            (resolve, reject) => {
                funcLib.fetchItemTypes().then(
                    response => {
                        let data = response.data.data

                        console.log('@fetchItemTypes', data)
                        commit('setTypeList', data)
                        resolve(data)
                    },
                    error => {
                        console.log(error)
                        reject(error)
                    }
                )
            }
        )
    },
    fetchSuitNames({commit}) {
        return new Promise(
            (resolve, reject) => {
                funcLib.fetchSuitNames().then(
                    response => {
                        let data = response.data.data

                        console.log('@fetchSuitNames', data)
                        commit('setSuitNames', data)
                        resolve(data)
                    },
                    error => {
                        console.log(error)
                        reject(error)
                    }
                )
            }
        )
    },
    fetchItemsByTypeId({commit}, typeId) {
        return new Promise(
            (resolve, reject) => {
                funcLib.fetchItemsByTypeId(typeId).then(
                    response => {
                        let data = response.data.data

                        console.log('@fetchItemsByTypeId', data)
                        commit('setItemList', data)
                        resolve(data)
                    },
                    error => {
                        console.log(error)
                        reject(error)
                    }
                )
            }
        )
    },
    fetchItemsBySuitId({commit}, suitId) {
        return new Promise(
            (resolve, reject) => {
                funcLib.fetchItemsBySuitId(suitId).then(
                    response => {
                        let data = response.data.data

                        console.log('@fetchItemsBySuitId', data)
                        commit('setItemList', data)
                        resolve(data)
                    },
                    error => {
                        console.log(error)
                        reject(error)
                    }
                )
            }
        )
    },
    changeActiveItem({commit, state}, item) {
        return new Promise(
            (resolve) => {
                if (!item || !state.activeItem || item.id !== state.activeItem.id) {
                    commit('setActiveItem', item)
                }

                resolve(item)
            }
        )
    },
    changeActiveType({commit}, type) {
        return new Promise(
            (resolve) => {
                commit('setActiveType', type)
                resolve(type)
            }
        )
    },
    fetchBaseInfo({commit}) {
        return new Promise(
            (resolve, reject) => {
                funcLib.fetchBaseInfo().then(
                    response => {
                        let data = response.data.data

                        console.log('@fetchBaseInfo', data)
                        commit('setTypeList', data.itemTypes)
                        commit('setSuitNames', data.suits)
                        // commit('setTypeList', data)
                        resolve(data)
                    },
                    error => {
                        console.log(error)
                        reject(error)
                    }
                )
            }
        )
    },
    addNewItem(context, item) {
        funcLib.addNewItem(item)
    },
    modifyItem(context, item) {
        funcLib.modifyItem(item)
    }
}

const mutations = {
    setActiveType(state, activeType) {
        state.activeType = activeType
    },
    setActiveItem(state, item) {
        console.log('@setActiveItem===================>', item)
        state.activeItem = item
    },
    setItemList(state, itemList) {
        state.itemList = itemList
    },
    setTypeList(state, typeList) {
       state.itemTypes = typeList
    },
    setSuitNames(state, suitNameList) {
        state.suitNames = suitNameList
    }
}

const state = {
    itemTypes: [],
    suitNames: [],
    itemList: [],
    activeItem: {},
    activeType: {},
}

export default new Vuex.Store({
    actions,
    mutations,
    state
})